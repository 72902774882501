<template>
  <div class="column-center" style="background-color: #f8f8f8">
    <div class="div-background regist-background" :style="{
        backgroundImage: `url(${icons.login_background})`,
      }"></div>

    <div class="regist-panel">
      <div class="form-item-container" style="margin-top: 30px; padding: 7px 30px">
        <img style="width: 18px; margin-right: 15px" :src="icons.register_info_detail" />
        <span style="font-size: 14px; color: #282828">填写信息详情</span>
      </div>

      <div class="form-item-container" style="margin-top: 30px; padding: 7px 30px">
        <a-select style="width: 300px" @change="handleOrganChange" placeholder="请选择推荐机构或推荐人">
          <a-select-option v-for="item in OrgArray" :key="item.organ_id" :value="item.organ_id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>

      <div class="form-item-container" style="margin-top: 30px; padding: 7px 30px">
        <a-select style="width: 300px" @change="handleUnitChange" placeholder="请选择参加单元">
          <a-select-option v-for="item in UnitArray" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>

      <div class="form-item-container" style="margin-top: 30px; padding: 7px 30px">
        <a-select style="width: 300px" @change="handleProvinceChange" placeholder="所在省">
          <a-select-option v-for="item in ProvinceArray" :key="item.PROVINCE_ID"
            :value="item.PROVINCE_ID + ',' + item.PROVINCE_CODE">
            {{ item.PROVINCE_NAME }}
          </a-select-option>
        </a-select>
      </div>

      <div class="form-item-container" style="margin-top: 30px; padding: 7px 30px">
        <a-select style="width: 300px" @change="handleCityChange" placeholder="所在市">
          <a-select-option v-for="item in CityArray" :key="item.CITY_CODE" :value="item.CITY_ID + ',' + item.CITY_CODE">
            {{ item.CITY_NAME }}
          </a-select-option>
        </a-select>
      </div>

      <div class="form-item-container" style="margin-top: 30px; padding: 7px 30px">
        <a-select style="width: 300px" @change="handleAreaChange" placeholder="所在区县">
          <a-select-option v-for="item in AreaArray" :key="item.AREA_CODE" :value="item.AREA_ID">
            {{ item.AREA_NAME }}
          </a-select-option>
        </a-select>
      </div>

      <div class="form-item-container" style="margin-top: 30px; padding: 7px 30px">
        <a-input v-model="form.name" placeholder="请填写小作者姓名"
          style="width: 300px; line-height: 32px; color: #656565; flex: 1" class="register-input" />
      </div>

      <div class="form-item-container" style="margin-top: 30px; padding: 7px 30px">
        <a-input v-model="form.en_name" placeholder="请填写小作者英文姓名，无英文名写拼音"
          style="width: 300px; line-height: 32px; color: #656565; flex: 1" class="register-input" />
      </div>

      <div class="form-item-container" style="margin-top: 30px; padding: 7px 30px">
        <a-input v-model="form.country" placeholder="请填写小作者国籍"
          style="width: 300px; line-height: 32px; color: #656565; flex: 1" class="register-input" />
      </div>

      <div class="form-item-container" style="margin-top: 30px; padding: 7px 30px">
        <a-select style="width: 300px" @change="handleSexChange" placeholder="请选择性别">
          <a-select-option v-for="item in SexArray" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>

      <div class="form-item-container" style="margin-top: 30px; padding: 7px 30px">
        <a-date-picker style="width: 300px" @change="onBirthdayChange" placeholder="请选择出生日期" />
      </div>

      <div class="form-item-container" style="margin-top: 30px; padding: 7px 30px">
        <a-input v-model="form.guardian_name" placeholder="请填写监护人姓名"
          style="width: 300px; line-height: 32px; color: #656565; flex: 1" class="register-input" />
      </div>

      <div class="form-item-container" style="margin-top: 30px; padding: 7px 30px">
        <a-input v-model="form.address" placeholder="请填写家庭地址"
          style="width: 300px; line-height: 32px; color: #656565; flex: 1" class="register-input" />
      </div>

      <div class="form-item-container" style="margin-top: 30px; padding: 7px 30px">
        <a-input v-model="form.phone" placeholder="请填写手机号"
          style="width: 300px; line-height: 32px; color: #656565; flex: 1" class="register-input" />
      </div>

      <div class="form-item-container" style="margin-top: 30px; padding: 7px 30px">
        <a-input v-model="form.sms_code" placeholder="请填写验证码"
          style="width: 300px; line-height: 32px; color: #656565; flex: 1" class="register-input" />
        <span class="regist-sms" @click="sendCode">{{
          duration == 60 ? "获取验证码" : duration + "秒"
        }}</span>
      </div>

      <div class="form-item-container" style="margin-top: 30px; padding: 7px 30px">
        <a-input v-model="form.email" placeholder="请填写邮箱"
          style="width: 300px; line-height: 32px; color: #656565; flex: 1" class="register-input" />
      </div>

      <div class="form-item-container row" style="margin-top: 30px; padding: 7px 30px">
        <div class="row" style="width: 300px">
          <div style="flex: 1">
            {{ form.group_id != null ? GroupArray[form.group_id] : "所属组别" }}
          </div>
          <div class="tip">根据生日自动识别</div>
        </div>
      </div>

      <div class="form-item-container row" style="margin-top: 30px; padding: 7px 30px; user-select: none">
        是否想把作品展示在“线上展厅”
        <a-radio-group style="margin-left: 15px" name="radioGroup" v-model="form.is_agree" :default-value="1">
          <a-radio :value="1"> 是 </a-radio>
          <a-radio :value="2"> 否 </a-radio>
        </a-radio-group>
      </div>

      <!-- <div @click="onRuleChange" class="form-item-container row"
        style="margin-top: 30px; padding: 7px 30px; cursor: pointer">
        <img style="
            width: 15px;
            height: 15px;
            margin-right: 10px;
            cursor: pointer;
            user-select: none;
          " :src="
            isCheck ? icons.register_agree_checked : icons.register_agree_normal
          " />
        已阅读并同意
        <div @click.stop="goRule" style="text-decoration: underline; color: #0088cd">
          《参展作品知识产权及相关声明》
        </div>
      </div> -->
    </div>

    <a-button style="margin-top: 70px" size="large" type="primary" @click="submit" :loading="submitBtnState.loading">
      下一步</a-button>

    <div style="height: 100px"></div>
  </div>
</template>

<script>
import moment from "moment";
import * as regHelper from "../../utils/regHelper";
import * as eventHelper from "../../utils/eventHelper";
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      icons: this.$root.icons,
      // 表单基础数据
      OrgArray: [],
      ProvinceArray: [],
      CityArray: [],
      AreaArray: [],
      SexArray: [
        {
          id: 1,
          name: "男",
        },
        {
          id: 2,
          name: "女",
        },
      ],
      GroupArray: ["无所属", "3-5岁", "6-7岁", "8-9岁", "10-12岁", "13-14岁"],
      UnitArray: [
        { id: 1, name: "主竞单元" },
        { id: 2, name: "小莫泊桑创造力单元" },
      ],
      OrgIndex: null,
      UnitIndex: null,
      ProvinceIndex: null,
      CityIndex: null,
      DistrictIndex: null,
      SexIndex: null,

      province_code: 0,
      city_code: 0,

      duration: 60,
      // isCheck: false,

      form: {
        organ_id: null,
        province_id: null,
        city_id: null,
        area_id: null,
        unit_id: null,
        name: "",
        en_name: "",
        country: "",
        sex: null,
        birthday: null,
        address: "",
        guardian_name: "",
        phone: "",
        sms_code: "",
        email: "",
        group_id: null,
        opus_name: "无",
        opus_desc: "无",
        recommand_name: "",
        is_agree: 1, // 1同意上传 2不同意上传
      },
      getSmsBtnState: {
        loading: false,
        disabled: false,
      },
      submitBtnState: {
        loading: false,
        disabled: false,
      },

      timer: null,
    };
  },
  mounted() {
    setTimeout(() => {
      eventHelper.triggerEvent("footer-change-background", {
        backgroundColor: "#062C3E",
        fontColor: "#ffffff",
      });
    }, 500);

    this.getOrganList();
    this.getProvinceList();
  },
  methods: {
    ...mapActions({
      signUpAction: "signUp",
      sendSMSAction: "sendSMS",
      selGetCityListAction: "selGetCityList",
      selGetAreaListAction: "selGetAreaList",
      selGetOrganListAction: "selGetOrganList",
      selGetProvinceListAction: "selGetProvinceList",
    }),
    handleOrganChange(value) {
      this.form.organ_id = value;
    },
    handleUnitChange(value) {
      this.form.unit_id = value;
    },
    handleProvinceChange(value) {
      let arr = value.split(",");
      this.form.province_id = arr[0];
      this.province_code = arr[1];
      this.getCityList();
    },
    handleCityChange(value) {
      let arr = value.split(",");
      this.form.city_id = arr[0];
      this.city_code = arr[1];
      this.getAreaList();
    },
    handleAreaChange(value) {
      this.form.area_id = value;
    },
    handleSexChange(value) {
      this.form.sex = value;
    },
    onBirthdayChange(date, dateString) {
      this.form.birthday = dateString;
      // 根据生日计算所属组别
      let age = moment().diff(moment(dateString), "year");
      let index = 0;
      if (age >= 3 && age <= 5) {
        index = 1;
      } else if (age == 6 || age == 7) {
        index = 2;
      } else if (age == 8 || age == 9) {
        index = 3;
      } else if (age >= 10 && age <= 12) {
        index = 4;
      } else if (age == 13 || age == 14) {
        index = 5;
      }
      this.form.group_id = index;
    },
    onAgreeChange() {
      this.form.is_agree = !this.form.is_agree;
    },
    // onRuleChange() {
    //   this.isCheck = !this.isCheck;
    // },
    getOrganList() {
      this.selGetOrganListAction()
        .then((res) => {
          this.OrgArray = res;
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    getProvinceList() {
      this.selGetProvinceListAction()
        .then((res) => {
          this.ProvinceArray = res;
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    getCityList() {
      this.selGetCityListAction({
        PROVINCE_CODE: this.province_code,
      })
        .then((res) => {
          this.CityArray = res;
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    getAreaList() {
      this.selGetAreaListAction({
        CITY_CODE: this.city_code,
      })
        .then((res) => {
          this.AreaArray = res;
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    goRule() {
      this.$router.push({
        name: "rule",
      });
    },
    checkPhone() {
      if (this.form.phone == "") {
        this.$message.error("请输入手机号");
        return false;
      }
      if (!regHelper.isPhone(this.form.phone)) {
        this.$message.error("手机号格式不正确");
        return false;
      }
      return true;
    },
    sendCode() {
      if (this.getSmsBtnState.loading) {
        return;
      }
      if (!this.checkPhone()) {
        return;
      }

      this.getSmsBtnState.disabled = true;
      this.getSmsBtnState.loading = true;
      this.sendSMSAction({
        phone: this.form.phone,
      })
        .then((res) => {
          this.duration = 60;
          this.timer = setInterval(() => {
            this.duration--;
            if (this.duration == 0) {
              this.duration = 60;
              this.getSmsBtnState.disabled = false;
              this.getSmsBtnState.loading = false;
              clearInterval(this.timer);
            }
          }, 1000);
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    submit() {
      if (this.form.organ_id == null) {
        this.$message.error("请选择报名类型");
        return;
      }
      if (this.form.unit_id == null) {
        this.$message.error("请选择参加单元");
        return;
      }
      if (this.form.province_id == null) {
        this.$message.error("请选择所在省");
        return;
      }
      if (this.form.city_id == null) {
        this.$message.error("请选择所在市");
        return;
      }
      if (this.form.area_id == null) {
        this.$message.error("请选择所在区县");
        return;
      }
      if (this.form.name == "") {
        this.$message.error("请填写姓名");
        return;
      }
      if (this.form.en_name == "") {
        this.$message.error("请填写英文名");
        return;
      }
      if (this.form.country == "") {
        this.$message.error("请填写国籍");
        return;
      }
      if (this.form.sex == null) {
        this.$message.error("请选择性别");
        return;
      }
      if (this.form.birthday == null) {
        this.$message.error("请选择出生日期");
        return;
      }
      if (this.form.address == "") {
        this.$message.error("请填写家庭地址");
        return;
      }
      if (this.form.guardian_name == "") {
        this.$message.error("请填写监护人姓名");
        return;
      }
      if (this.form.phone == "") {
        this.$message.error("请填写手机号");
        return;
      }
      if (this.form.sms_code == "") {
        this.$message.error("请填写验证码");
        return;
      }
      if (this.form.email == "") {
        this.$message.error("请填写邮箱");
        return;
      }
      if (!regHelper.isEmail(this.form.email)) {
        this.$message.error("邮箱格式不正确");
        return;
      }
      // if (!this.isCheck) {
      //   this.$message.error("请先阅读并同意参赛须知");
      //   return;
      // }

      this.signUpAction(this.form)
        .then((res) => {
          // this.$message.success("注册成功");
          this.$router.push({
            name: "rule",
          });
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.regist-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.regist-panel {
  border-radius: 10px;
  background-color: #fff;
  padding: 18px;
  width: 710px;
  margin-top: 35px;
}

.regist-sms {
  font-size: 13px;
  color: #295a9f;
  margin-left: 20px;
  cursor: pointer;
}

.tip {
  font-size: 12px;
  color: #cccccc;
}
.register-input {
  border: 1px solid #ccc;
  font-size: 14px;
  outline: none;
  background-color: transparent;
}
</style>